import React from 'react';
import { useContext } from 'react';
import reasons from "../data/5reasons.json";
import { UserContext } from "../context/UserContext";

const GetTitleFromPoint = ({point}) => {
  const { isParent } = useContext(UserContext);
  return (
    <h2 className="text-lg font-semibold">
      {isParent ? point.title : point.title_kind}
    </h2>
  );
};

const GetDescriptionFromPoint = ({point}) => {
  const { isParent } = useContext(UserContext);
  return (
    <p className="text-md md:text-base">
      {isParent ? point.description : point.description_kind}
    </p>
  );
};

const ReasonPoint = ({ point, index }) => {
  const [imgSrc, setImgSrc] = React.useState(point.iconUrl);
  const [isActive, setIsActive] = React.useState(false);
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const handleImageError = () => {
    console.error(`Failed to load image: ${imgSrc}`);
    setImgSrc("./fallback-image.jpg");
  };

  const isReversed = index % 2 !== 0;

  const toggleOverlay = (e) => {
    if (isMobile) {
      e.preventDefault();
      setIsActive(!isActive);
    }
  };

  return (
    <div 
      className="relative w-full max-w-4xl mx-auto mb-8 rounded-2xl transition-transform duration-300 hover:scale-105"
      onClick={toggleOverlay}
      onMouseEnter={() => !isMobile && setIsActive(true)}
      onMouseLeave={() => !isMobile && setIsActive(false)}
      role="button"
      tabIndex={0}
      aria-expanded={isActive}
    >
      {/* Base Content */}
      <div className={`flex flex-col md:flex-row ${isReversed ? 'md:flex-row-reverse' : ''}`}>
        <div className="w-full md:w-1/3 p-0 md:p-6 flex items-center justify-center">
          <img
            src={imgSrc}
            alt={point.title}
            className="w-2/3 md:w-full h-auto object-contain border-3 rounded-xl"
            onError={handleImageError}
          />
        </div>
        <div className="w-full md:w-2/3 p-0 md:p-6 md:ml-6 flex flex-col justify-center">
          <div className="my-2 text-2xl md:text-4xl font-bold text-yellow-400 text-center md:text-left">
            {point.nmbr}
          </div>
          <GetTitleFromPoint point={point} />
        </div>
      </div>

      {/* Overlay */}
      <div
        className={`absolute -left-12 -right-12 -top-6 -bottom-6 md:inset-0 bg-custom_primary_light rounded-2xl p-4 md:p-8 transition-all duration-300 flex items-center justify-center
          ${isActive ? 'opacity-100 visible' : 'opacity-0 invisible pointer-events-none'}`}
      >
        <div className="text-gray-900 max-w-prose">
          <GetDescriptionFromPoint point={point} />
        </div>
      </div>
    </div>
  );
};

const FiveReasons = () => (
  <div className="container mx-auto px-4 py-8 md:py-16">
    <h1 className="text-xl md:text-4xl font-bold text-gray-800 text-center mb-8 md:mb-12">
      Fünf Gründe warum dein Kind Programmieren lernen kann und sollte:
    </h1>
    <div className="space-y-8">
      {reasons.map((point, index) => (
        <ReasonPoint key={index} point={point} index={index} />
      ))}
    </div>
  </div>
);

export default FiveReasons;