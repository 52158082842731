import React, { useState, useEffect } from 'react';
import {
  MainContent,
  HeaderWrapper,
  ContentContainer,
  HeaderContainer,
  Title
} from "./StyledComponents";

const BookingCard = ({ title, isActive, children }) => (
  <div className={`w-full ${isActive ? 'block' : 'hidden'}`}>
    
      <h2 className="text-xl font-bold mb-4">{title}</h2>
      {children}

  </div>
);

const NavigationBar = ({ currentStep, setCurrentStep, formData }) => {
  const steps = [
    "Kontaktdaten",
    "Rechnungsadresse",
    "Zahlungsoptionen",
    "Teilnehmerinfo",
    "Übersicht"
  ];

  return (
    <div className="mb-8 overflow-x-auto">
      <div className="flex justify-between items-center min-w-[400px] px-0">
        {steps.map((step, index) => (
          <div key={index} className="flex items-center">
            <button
              onClick={() => setCurrentStep(index)}
              className={`rounded-full w-8 h-8 flex items-center justify-center ${
                index <= currentStep
                  ? 'bg-custom_tertiary text-white'
                  : 'bg-gray-200 text-gray-600'
              }`}
            >
              {index + 1}
            </button>
            <span className="ml-2 hidden md:inline text-sm">{step}</span>
            {index < steps.length - 1 && (
              <div className="h-1 w-12 mx-2 bg-gray-200">
                <div
                  className={`h-full ${
                    index < currentStep ? 'bg-custom_tertiary' : 'bg-gray-200'
                  }`}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

function Booking() {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({
    // Parent/Guardian Information
    parentSalutation: '',
    parentFirstName: '',
    parentLastName: '',
    parentEmail: '',
    parentPhone: '',
    
    // Billing Address
    street: '',
    houseNumber: '',
    postalCode: '',
    city: '',
    country: 'Deutschland',
    
    // Payment Information
    paymentMethod: '',
    
    // Student Information
    studentFirstName: '',
    studentLastName: '',
    studentAge: '',
    studentExperience: '',
    
    // Terms and Conditions
    termsAccepted: false,
    privacyAccepted: false
  });

  // Load course selection data from sessionStorage
  /*
  useEffect(() => {
    const courseData = sessionStorage.getItem('courseSelection');
    if (courseData) {
      const parsedData = JSON.parse(courseData);
      // You can use this data to display course information in the overview
    }
  }, []);
  */

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  useEffect(() => {
    // Save form data to sessionStorage whenever it changes
    sessionStorage.setItem('bookingFormData', JSON.stringify(formData));
  }, [formData]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you'll add the logic to forward the data
    console.log('Form submitted:', formData);
  };

  return (
    <MainContent>
      <HeaderContainer>
        <HeaderWrapper>
          <Title>Buchung</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
        <NavigationBar 
          currentStep={currentStep} 
          setCurrentStep={setCurrentStep}
          formData={formData}
        />

        {/* Parent/Guardian Information Card */}
        <BookingCard title="Kontaktdaten" isActive={currentStep === 0}>
          <form className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Anrede</label>
                <select
                  type="text"
                  name="parentSalutation"
                  value={formData.parentSalutation}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                >
                  <option value="none"> - </option>
                  <option value="Frau">Frau</option>
                  <option value="Herr">Herr</option>
                  </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Vorname</label>
                <input
                  type="text"
                  name="parentFirstName"
                  value={formData.parentFirstName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Nachname</label>
                <input
                  type="text"
                  name="parentLastName"
                  value={formData.parentLastName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="parentEmail"
                  value={formData.parentEmail}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Telefon</label>
                <input
                  type="tel"
                  name="parentPhone"
                  value={formData.parentPhone}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                />
              </div>
            </div>
          </form>
        </BookingCard>

        {/* Billing Address Card */}
        <BookingCard title="Rechnungsadresse" isActive={currentStep === 1}>
          <form className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Straße</label>
                <input
                  type="text"
                  name="street"
                  value={formData.street}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Hausnummer</label>
                <input
                  type="text"
                  name="houseNumber"
                  value={formData.houseNumber}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">PLZ</label>
                <input
                  type="text"
                  name="postalCode"
                  value={formData.postalCode}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Stadt</label>
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                />
              </div>
            </div>
          </form>
        </BookingCard>

        {/* Payment Options Card */}
        <BookingCard title="Zahlungsoptionen" isActive={currentStep === 2}>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Zahlungsmethode</label>
              <select
                name="paymentMethod"
                value={formData.paymentMethod}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                required
              >
                <option value="">Bitte wählen</option>
                <option value="sepa">SEPA-Lastschrift</option>
                <option value="bank">Überweisung</option>
                <option value="card">Kreditkarte</option>
              </select>
            </div>
          </div>
        </BookingCard>

        {/* Student Information Card */}
        <BookingCard title="Teilnehmerinfo" isActive={currentStep === 3}>
          <form className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Vorname</label>
                <input
                  type="text"
                  name="studentFirstName"
                  value={formData.studentFirstName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Nachname</label>
                <input
                  type="text"
                  name="studentLastName"
                  value={formData.studentLastName}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Alter</label>
                <input
                  type="number"
                  name="studentAge"
                  value={formData.studentAge}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Programmiererfahrung</label>
                <select
                  name="studentExperience"
                  value={formData.studentExperience}
                  onChange={handleInputChange}
                  className="mt-1 block w-full p-1 rounded-md border-[1px] border-gray-300 focus:border-[2px] focus:border-solid focus:border-custom_primary focus:outline-none"
                  required
                >
                  <option value="">Bitte wählen</option>
                  <option value="none">Keine Erfahrung</option>
                  <option value="basic">Grundkenntnisse</option>
                  <option value="intermediate">Fortgeschritten</option>
                </select>
              </div>
            </div>
          </form>
        </BookingCard>

        {/* Overview Card */}
        <BookingCard title="Übersicht" isActive={currentStep === 4}>
          <div className="space-y-6">
            <div className="border-b pb-4">
              <h3 className="text-lg font-medium">Kursdetails</h3>
              {/* Course details will be populated from sessionStorage */}
            </div>
            
            <div className="space-y-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="termsAccepted"
                  checked={formData.termsAccepted}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-custom_tertiary"
                  required
                />
                <label className="ml-2 text-sm text-gray-700">
                  Ich akzeptiere die <a href="/agb" className="text-custom_tertiary">AGB</a>
                </label>
              </div>
              
              <div className="flex items-center">
                <input
                  type="checkbox"
                  name="privacyAccepted"
                  checked={formData.privacyAccepted}
                  onChange={handleInputChange}
                  className="h-4 w-4 text-custom_tertiary"
                  required
                />
                <label className="ml-2 text-sm text-gray-700">
                  Ich akzeptiere die <a href="/datenschutz" className="text-custom_tertiary">Datenschutzerklärung</a>
                </label>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              disabled={!formData.termsAccepted || !formData.privacyAccepted}
              className="w-full bg-custom_tertiary text-white py-2 px-4 rounded-3xl hover:bg-custom_primary transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Jetzt verbindlich buchen
            </button>
          </div>
        </BookingCard>

        {/* Navigation Buttons */}
        <div className="flex justify-between mt-6">
          <button
            onClick={() => setCurrentStep(Math.max(0, currentStep - 1))}
            className={`px-4 py-2 bg-gray-200 text-gray-700 rounded-3xl hover:bg-gray-300 ${
              currentStep === 0 ? 'invisible' : ''
            }`}
          >
            Zurück
          </button>
          <button
            onClick={() => setCurrentStep(Math.min(4, currentStep + 1))}
            className={`px-4 py-2 bg-custom_tertiary text-white rounded-3xl hover:bg-custom_primary ${
              currentStep === 4 ? 'invisible' : ''
            }`}
          >
            Weiter
          </button>
        </div>
      </ContentContainer>
    </MainContent>
  );
}

export default Booking;