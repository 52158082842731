// src/context/BookingContext.js
import React, { createContext, useContext, useState } from 'react';

const BookingContext = createContext();

export const BookingProvider = ({ children }) => {
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState(new Set());
  const [selectedMonth, setSelectedMonth] = useState(null);

  const resetSelections = () => {
    setSelectedPackage(null);
    setSelectedTimeSlots(new Set());
    setSelectedMonth(null);
  };

  const value = {
    selectedPackage,
    setSelectedPackage,
    selectedTimeSlots,
    setSelectedTimeSlots,
    selectedMonth,
    setSelectedMonth,
    resetSelections,
  };

  return (
    <BookingContext.Provider value={value}>
      {children}
    </BookingContext.Provider>
  );
};

export const useBooking = () => {
  const context = useContext(BookingContext);
  if (context === undefined) {
    throw new Error('useBooking must be used within a BookingProvider');
  }
  return context;
};