import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { MainContentWrapper, MainContent, PageWrapper } from "./components/StyledComponents";
import BreadcrumbStructuredData from "./components/BreadcrumbStructuredData.js";
import Navbar from "./components/NavigationBar_tw.js";
import Home from "./components/Home_Element.js";
import Courses from "./components/Courses";
import Team from "./components/Team";
import Info from "./components/Info";
import Contact from "./components/Contact";
import Quiz from "./components/Quiz.js";
import QuizCarousel from "./components/QuizCarousel.js";
import Footer from "./components/Footer.js";
import Datenschutz from "./components/Datenschutz.js";
import Impressum from "./components/Impressum.js";
import FAQ from "./components/FAQ.js";
import "bootstrap/dist/css/bootstrap.min.css";
import CourseById from "./components/CourseById.js";
import { UserProvider } from "./context/UserContext.js";
import UserTypeButton from "./components/UserTypeButton.js";
import CookieConsent from "./components/CookieConsent"; // CookieConsent component
import { trackPageView } from "./analytics"; // Import analytics tracking function
import PageBottom from './components/PageBottom';
import Jobs from "./components/Jobs.js";
import Promotions from "./components/Promotions.js";
import PromoPopup from "./components/PromoPupUp.js";
import ScrollToTop from "./components/ScrollToTop.js";
import Preise from "./components/Preise.js";
import ScrollTopButton from "./components/BackToTop.js";
import AGB from "./components/AGB.js"
import Booking from "./components/Booking.js";
import { BookingProvider } from './context/BookingContext';
import { Outlet } from 'react-router-dom';
import FallingBackground from "./components/FallingBackground.js";

const BookingLayout = () => {
  return (
    <BookingProvider>
      <Outlet />
    </BookingProvider>
  );
};

// Component for tracking page views
const AnalyticsWrapper = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    trackPageView(location.pathname); // Track page view if consent is given
  }, [location]);

  return children;
};

function App() {
  const svgImages = [
   // '/backgroundSVGs/controller.svg',
   // '/backgroundSVGs/lightbulb.svg',
   // '/backgroundSVGs/mim.svg', 
   // '/backgroundSVGs/react.svg',
   // '/backgroundSVGs/robot.svg',
   // '/backgroundSVGs/scratch.svg', 
   // '/backgroundSVGs/sword.svg', 
    '/backgroundSVGs/CS.svg'
  ];

  return (
    <UserProvider>
      <PageWrapper>
      <FallingBackground 
          svgCount={10} 
          minSpeed={15} 
          maxSpeed={35} 
          svgImages={svgImages}
          minSize={100}
          maxSize={120}
        />
        <Router>
          <ScrollToTop />
          <BreadcrumbStructuredData />
          <Navbar />
          <UserTypeButton />
          <AnalyticsWrapper>
            <MainContentWrapper>
              <MainContent>
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route element={<BookingLayout />}>
                    <Route path="/preise" element={<Preise />} />
                    <Route path="/courses" element={<Courses />} />
                    <Route path="/courses/:id" element={<CourseById />} />
                    <Route path="/booking" element={<Booking />} />
                  </Route>
                  <Route path="/team" element={<Team />} />
                  <Route path="/info" element={<Info />} />
                  <Route path="/quiz" element={<Quiz />} />
                  <Route path="/quizCarousel" element={<QuizCarousel />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/datenschutz" element={<Datenschutz />} />
                  <Route path="/impressum" element={<Impressum />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/jobs" element={<Jobs />} />
                  <Route path="/promo" element={<Promotions />} />
                  <Route path="/agb" element={<AGB />} />
                </Routes>
              </MainContent>
              
              <ScrollTopButton />
              <PageBottom />
            </MainContentWrapper>
          </AnalyticsWrapper>
          <CookieConsent />
          <PromoPopup />
          <Footer />
        </Router>
      </PageWrapper>
    </UserProvider>
  );
}

export default App;
