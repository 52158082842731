import { 
  MainContent, 
  HeaderWrapper, 
  ContentContainer, 
  HeaderContainer, 
  Title,
} 
from "./StyledComponents";
import styles from './Courses.module.css';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { useContext, useState, useEffect } from 'react';
import { UserContext } from '../context/UserContext';
import coursesData from '../data/courses.json'

const pfeilstyle1 = {
strokeColor: '#00b8c6',
strokeWidth: 4,
lineStyle: 'curve',
endMarker: false 
}

const pfeilstyle2 = {
  strokeColor: '#e076ff',
  strokeWidth: 4,
  lineStyle: 'curve',
  endMarker: false 
  }

  const CourseCard = ({ course }) => (
    <a href={`/courses/${course.id}`} className={styles.course_link}> 
    <div className={styles.card}>
      <h4 className={styles.card_header}>{course.title}</h4>
      <ul className={styles.card_list}>
        {course.course_contents.slice(0, 3).map((content, index) => (
          <li key={index}>{content}</li>
        ))}
      </ul>
      <p  className={styles.more_course}>mehr...</p>
    </div>
    </a>
  );

function Courses() {
  const { isParent } = useContext(UserContext);
  const [currentCardRelations, setCurrentCardRelations] = useState(null);
  
  const courseCards = coursesData.courses.reduce((acc, course) => {
    acc[course.id] = <CourseCard key={course.id} course={course} />;
    return acc;
  }, {});

  useEffect(() => {
    // Function to set the appropriate card relations based on screen width
    const setCardRelationsForScreenSize = () => {
      const isWideScreen = window.matchMedia("(min-width: 1198px)").matches;

      if (isWideScreen) {
        // Desktop relations
        setCurrentCardRelations({
          1: [ //basics
            { targetId: 'wd1', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle1 },
            { targetId: 'gd1', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle2 }
          ],
          4: [ //wd1
            { targetId: 'wd2', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle1 },
            { targetId: 'python', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle1 },
            { targetId: 'AppML', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle1 }
          ],
          7: [//gd1
            { targetId: 'gd2', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle2 },
            { targetId: 'python', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle2 },
            { targetId: 'AppML', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle2 }
          ]
        });
      } else {
        // Mobile relations with different anchors
        setCurrentCardRelations({
          1: [ //basics
            { targetId: 'oneleft', targetAnchor: 'top', sourceAnchor: 'left', style: pfeilstyle1 },
            { targetId: 'oneright', targetAnchor: 'top', sourceAnchor: 'right', style: pfeilstyle2 }
          ],
          2: [ //oneleft
            { targetId: 'wd1', targetAnchor: 'left', sourceAnchor: 'bottom', style: pfeilstyle1 }
          ],
          3: [ //oneright
            { targetId: 'tworight', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle2 }
          ],
          4: [ //wd1
            { targetId: 'twoleft', targetAnchor: 'top', sourceAnchor: 'left', style: pfeilstyle1 }
          ],
          5: [ //twoleft
            { targetId: 'threeleft', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle1 }
          ],
          6: [//tworight
            { targetId: 'gd1', targetAnchor: 'right', sourceAnchor: 'bottom', style: pfeilstyle2 }
          ],
          7: [ //gd1
            { targetId: 'threeright', targetAnchor: 'top', sourceAnchor: 'right', style: pfeilstyle2 }
          ],
          8: [ //threeleft
            { targetId: 'wd2', targetAnchor: 'left', sourceAnchor: 'bottom', style: pfeilstyle1 },
            { targetId: 'fourleft', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle1 }
          ],
          9: [ //threeright
            { targetId: 'fourright', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle2 }
          ],
          10: [ //fourleft
            { targetId: 'fiveleft', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle1 },
            { targetId: 'AppML', targetAnchor: 'left', sourceAnchor: 'bottom', style: pfeilstyle1 }
          ],
          11: [ //fourright
            { targetId: 'AppML', targetAnchor: 'right', sourceAnchor: 'bottom', style: pfeilstyle2 },
            { targetId: 'fiveright', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle2 }
          ],
          12: [ //fiveleft
            { targetId: 'python', targetAnchor: 'left', sourceAnchor: 'bottom', style: pfeilstyle1 }
          ],
          13: [ //fiveright
            { targetId: 'python', targetAnchor: 'right', sourceAnchor: 'bottom', style: pfeilstyle2 },
            { targetId: 'sixright', targetAnchor: 'top', sourceAnchor: 'bottom', style: pfeilstyle2 }
          ],
          14: [ //sixright
            { targetId: 'gd2', targetAnchor: 'right', sourceAnchor: 'bottom', style: pfeilstyle2 }
          ]
        });
      }
    };

    // Set initial card relations
    setCardRelationsForScreenSize();

    // Create a media query list
    const mediaQuery = window.matchMedia("(min-width: 1198px)");

    // Add event listener for screen size changes
    const handleScreenChange = (e) => {
      setCardRelationsForScreenSize();
    };

    mediaQuery.addListener(handleScreenChange);
    return () => {
      mediaQuery.removeListener(handleScreenChange);
    };
  }, []); // Empty dependency array means this runs once on mount

  // Check for both isParent and currentCardRelations
  if (isParent === undefined || currentCardRelations === null) {
    return <div>Loading...</div>;
  }
  return (
    <MainContent>
      <HeaderContainer>
        <HeaderWrapper>
          <Title>Kursübersicht</Title>
          </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
        {isParent ? (
            <>
              <div className={styles.text_block_top}> 
              <p className={styles.paragraph}>
                Der Lehrplan der Codestart Academy besteht aus drei aufeinander aufbauenden Leveln: 
                </p>
              </div>
              <ArcherContainer className={styles.parent}>
                  <h1 className={styles.head}>CodeStart Academy<br/>Lehrplan</h1>
                  <div className={styles.row} id={styles.level1}> 
                    <div className={styles.box1}>
                      <ArcherElement id="root" relations={currentCardRelations[1]}>
                          <div className={styles.cardContainer}>
                            {courseCards[1]}
                          </div>
                      </ArcherElement>
                    </div>
                    <p className={styles.level_nr1}>1</p>
                  </div>

                  <div className={styles.zwischenAnker}>
                    <ArcherElement id="oneleft" relations={currentCardRelations[2]}><div className={styles.Anker_element}></div></ArcherElement>
                    <ArcherElement id="oneright" relations={currentCardRelations[3]}><div className={styles.Anker_element}></div></ArcherElement>
                  </div>

                  <div className={styles.row} id={styles.level2}>
                    <div className={styles.box2a}> 
                      <ArcherElement id="wd1" relations={currentCardRelations[4]}>
                        <div className={styles.cardContainer}>
                          {courseCards[2]}
                        </div>
                      </ArcherElement>
                    </div>

                    <div className={styles.zwischenAnker}>
                      <ArcherElement id="twoleft" relations={currentCardRelations[5]}><div className={styles.Anker_element}></div></ArcherElement>
                      <ArcherElement id="tworight" relations={currentCardRelations[6]}><div className={styles.Anker_element}></div></ArcherElement>
                  </div>

                    <div className={styles.box2b}> 
                      <ArcherElement id="gd1" relations={currentCardRelations[7]}>
                        <div className={styles.cardContainer}>
                          {courseCards[3]}
                        </div>
                      </ArcherElement>
                    </div>
                    <p className={styles.level_nr2}>2</p>
                  </div>

                  <div className={styles.zwischenAnker}>
                    <ArcherElement id="threeleft" relations={currentCardRelations[8]}><div className={styles.Anker_element}></div></ArcherElement>
                    <ArcherElement id="threeright" relations={currentCardRelations[9]}><div className={styles.Anker_element}></div></ArcherElement>
                  </div>

                  <div className={styles.row2} id={styles.level3}>
                    <div className={styles.box3}> 
                      <ArcherElement id="wd2">
                        <div className={styles.cardContainer}>
                          {courseCards[4]}
                        </div>
                      </ArcherElement>
                    </div>
                    <div className={styles.zwischenAnker}>
                      <ArcherElement id="fourleft" relations={currentCardRelations[10]}><div className={styles.Anker_element}></div></ArcherElement>
                      <ArcherElement id="fourright" relations={currentCardRelations[11]}><div className={styles.Anker_element}></div></ArcherElement>
                    </div>
                    <div className={styles.box3}> 
                      <ArcherElement id="AppML">
                        <div className={styles.cardContainer}>
                          {courseCards[5]}
                        </div>
                      </ArcherElement>
                    </div>
                    <div className={styles.zwischenAnker}>
                      <ArcherElement id="fiveleft" relations={currentCardRelations[12]}><div className={styles.Anker_element}></div></ArcherElement>
                      <ArcherElement id="fiveright" relations={currentCardRelations[13]}><div className={styles.Anker_element}></div></ArcherElement>
                    </div>
                    <div className={styles.box3}> 
                      <ArcherElement id="python">
                        <div className={styles.cardContainer}>
                          {courseCards[6]}
                        </div>
                      </ArcherElement>
                    </div>
                    <div className={styles.zwischenAnker}>
                      <ArcherElement id="sixleft"><div className={styles.Anker_element}></div></ArcherElement>
                      <ArcherElement id="sixright" relations={currentCardRelations[14]}><div className={styles.Anker_element}></div></ArcherElement>
                    </div>
                    <div className={styles.box3}> 
                      <ArcherElement id="gd2">
                      <div className={styles.cardContainer}>
                          {courseCards[7]}
                        </div>
                      </ArcherElement>
                    </div>
                    <p className={styles.level_nr3}>3</p>
                  </div>
              </ArcherContainer>
              <div className={styles.text_block_bottom}> 
              <h2 className={styles.Level1_title}>Level 1</h2>
                <p className={styles.paragraph}>
                Das erste Level besteht aus dem <a className='no-underline text-custom_tertiary font-bold' href="/courses/1">CS Grundlagen Kurs</a>, in dem unsere jüngsten und unerfahrensten CodeKids sich die grundlegenden Fähigkeiten aneignen können, die sie benötigen, um ihr Abenteuer in der digitalen Welt zu starten. Wir beginnen einfach und spielerisch und arbeiten uns dabei kinderleicht durch alle wichtigen Themen, von der Logik des Programmierens, über das Verhalten im Internet bis zu denkenden Computern, der künstlichen Intelligenz.
                </p>
                <h2 className={styles.Level2_title}> Level 2 </h2>
                <p className={styles.paragraph}>
                  Im zweiten Level spaltet sich unser Lehrplan zum ersten mal und unsere CodeKids dürfen wählen, ob es eher kreativ mit Webdesign oder logisch mit Spieleentwicklung weitergehen soll. Im <a className='no-underline text-custom_tertiary font-bold' href="/courses/2">CS Webdesign</a> Kurs beschäftigen wir uns mit den Grundbausteinen des Internets, wie man Webseiten scriptet und was ein gutes Design ausmacht. Im <a className='no-underline text-custom_tertiary font-bold' href="/courses/3">CS Spieldesign</a> Kurs arbeiten wir mit dem Scratch Game Engine, beschäftigen uns mit der komplexen Logik, die hinter klassischen Spielkonzepten steckt und wie man diese in eine Programmiersprache 'übersetzt'.
                </p>
                <h2 className={styles.Level3_title}> Level 3 </h2>
                <p className={styles.paragraph}>
                  Ausschließlich den Absolventen eines Level zwei Kurses der CodeStart Academy steht der Weg zu Level drei Kursen offen und hier entwickeln sich unsere CodeKids zu echten Spezialisten. Die Webdesign Projekte werden größer in <a className='no-underline text-custom_tertiary font-bold' href="/courses/4">CS Webdesign 2</a>, die Spielmechaniken komplexer in <a className='no-underline text-custom_tertiary font-bold' href="/courses/7">CS Spieldesign 2</a>. Und wer Lust auf etwas neues hat kann sich mit dem Design von mobilen Apps in <a className='no-underline text-custom_tertiary font-bold' href="/courses/5">CS Appdesign</a> oder in <a className='no-underline text-custom_tertiary font-bold' href="/courses/6">CS Python</a> mit der vielseitigen und beliebten Programmiersprache Python beschäftigen.
                </p>
              </div>
            </>
            ) : (
              <>
                <div className={styles.text_block_top}> 
                <p className={styles.paragraph}>
                  Bei uns an der Codestart Academy gibt es drei Level: 
                  </p>
                </div>
                <ArcherContainer className={styles.parent}>
                  <h1 className={styles.head}>CodeStart Academy<br/>Lehrplan</h1>
                  <div className={styles.row} id={styles.level1}> 
                    <div className={styles.box1}>
                      <ArcherElement id="root" relations={currentCardRelations[1]}>
                          <div className={styles.cardContainer}>
                            {courseCards[1]}
                          </div>
                      </ArcherElement>
                    </div>
                    <p className={styles.level_nr1}>1</p>
                  </div>

                  <div className={styles.zwischenAnker}>
                    <ArcherElement id="oneleft" relations={currentCardRelations[2]}><div className={styles.Anker_element}></div></ArcherElement>
                    <ArcherElement id="oneright" relations={currentCardRelations[3]}><div className={styles.Anker_element}></div></ArcherElement>
                  </div>

                  <div className={styles.row} id={styles.level2}>
                    <div className={styles.box2a}> 
                      <ArcherElement id="wd1" relations={currentCardRelations[4]}>
                        <div className={styles.cardContainer}>
                          {courseCards[2]}
                        </div>
                      </ArcherElement>
                    </div>

                    <div className={styles.zwischenAnker}>
                      <ArcherElement id="twoleft" relations={currentCardRelations[5]}><div className={styles.Anker_element}></div></ArcherElement>
                      <ArcherElement id="tworight" relations={currentCardRelations[6]}><div className={styles.Anker_element}></div></ArcherElement>
                  </div>

                    <div className={styles.box2b}> 
                      <ArcherElement id="gd1" relations={currentCardRelations[7]}>
                        <div className={styles.cardContainer}>
                          {courseCards[3]}
                        </div>
                      </ArcherElement>
                    </div>
                    <p className={styles.level_nr2}>2</p>
                  </div>

                  <div className={styles.zwischenAnker}>
                    <ArcherElement id="threeleft" relations={currentCardRelations[8]}><div className={styles.Anker_element}></div></ArcherElement>
                    <ArcherElement id="threeright" relations={currentCardRelations[9]}><div className={styles.Anker_element}></div></ArcherElement>
                  </div>

                  <div className={styles.row2} id={styles.level3}>
                    <div className={styles.box3}> 
                      <ArcherElement id="wd2">
                        <div className={styles.cardContainer}>
                          {courseCards[4]}
                        </div>
                      </ArcherElement>
                    </div>
                    <div className={styles.zwischenAnker}>
                      <ArcherElement id="fourleft" relations={currentCardRelations[10]}><div className={styles.Anker_element}></div></ArcherElement>
                      <ArcherElement id="fourright" relations={currentCardRelations[11]}><div className={styles.Anker_element}></div></ArcherElement>
                    </div>
                    <div className={styles.box3}> 
                      <ArcherElement id="AppML">
                        <div className={styles.cardContainer}>
                          {courseCards[5]}
                        </div>
                      </ArcherElement>
                    </div>
                    <div className={styles.zwischenAnker}>
                      <ArcherElement id="fiveleft" relations={currentCardRelations[12]}><div className={styles.Anker_element}></div></ArcherElement>
                      <ArcherElement id="fiveright" relations={currentCardRelations[13]}><div className={styles.Anker_element}></div></ArcherElement>
                    </div>
                    <div className={styles.box3}> 
                      <ArcherElement id="python">
                        <div className={styles.cardContainer}>
                          {courseCards[6]}
                        </div>
                      </ArcherElement>
                    </div>
                    <div className={styles.zwischenAnker}>
                      <ArcherElement id="sixleft"><div className={styles.Anker_element}></div></ArcherElement>
                      <ArcherElement id="sixright" relations={currentCardRelations[14]}><div className={styles.Anker_element}></div></ArcherElement>
                    </div>
                    <div className={styles.box3}> 
                      <ArcherElement id="gd2">
                      <div className={styles.cardContainer}>
                          {courseCards[7]}
                        </div>
                      </ArcherElement>
                    </div>
                    <p className={styles.level_nr3}>3</p>
                  </div>
              </ArcherContainer>
                 <div className={styles.text_block_bottom}> 
                <h2 className={styles.Level1_title}>Level 1</h2>
                <div className={styles.paragraph}>
                    <p>
                    Bist du bereit für dein erstes digitales Abenteuer? Im CS Grundlagen Kurs in Level 1 lernst du alles, was du brauchst, um in der Welt der Computer durchzustarten. Wir machen das ganz einfach und mit viel Spaß! Du wirst lernen, wie man Computer zum Denken bringt, wie man sicher im Internet unterwegs ist und sogar etwas über künstliche Intelligenz – das sind Computer, die fast wie Menschen denken können!
                    </p>
                  </div>
                  <h2 className={styles.Level2_title}> Level 2 </h2>
                  <div className={styles.paragraph}>
                  <p>
                    Im zweiten Level wird es richtig spannend, denn jetzt darfst du selbst entscheiden, wie es weitergeht:
                    </p>
                    <ul className="list-disc">
                      <li>Möchtest du lernen, wie man coole Webseiten macht? Dann ist der Webdesign-Kurs genau das Richtige für dich! Du lernst, wie das Internet funktioniert und wie du deine eigenen tollen Webseiten erstellen kannst.</li>
                      <li>Oder willst du lieber deine eigenen Spiele entwickeln? Im Spieldesign-Kurs arbeitest du mit einem Tool namens Scratch. Damit kannst du lernen, wie man Spiele programmiert und zum Leben erweckt!</li>
                    </ul>
                  </div>
                  <h2 className={styles.Level3_title}> Level 3 </h2>
                  <div className={styles.paragraph}>
                    <p>
                      Level 3 ist für die echten Experten. Hier kannst du dein Wissen weiter ausbauen:
                    </p>
                    <ul className="list-disc">
                      <li>Im CS Webdesign 2 Kurs baust du noch größere und coolere Webseiten.</li>
                      <li>Im CS Spieldesign 2 Kurs erstellt du spiele mit richtig kniffligen Rätseln und spannenden Geschichten.</li>
                      <li>Im CS Appdesign Kurs lernst du mehr über künsltiche Intelligenz und wie man coole Apps baut!</li>                      
                      <li>Im CS Python Kurs lernst du eine richtig gute Programmiersprache kennen und wie man damit Computern das sehen beibringen kann.</li>
                    </ul>
                    <p>Egal was du wählst. Hier wirst du ein echter Profi in der Welt der Computer!</p>
                  </div>
                </div>
              </>
              )
            }
      </ContentContainer>
    </MainContent>
  );
}

export default Courses;