import React, { useState, useEffect } from 'react';

const TestimonialCarousel = ({ testimonials }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  
  const handleSlide = (newIndex) => {
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      const newIndex = currentIndex === testimonials.length - 1 ? 0 : currentIndex + 1;
      handleSlide(newIndex);
    }, 5000);
    
    return () => clearInterval(timer);
  }, [currentIndex, testimonials.length]);

  return (
    <div className="w-full py-12 z-1">
      <h2 className="text-xl md:text-3xl font-bold text-custom_neutral mb-6 text-center z-10">
        Von unseren CodeKids
      </h2>
      <div className="max-w-2xl mx-auto px-4">
        <div className="relative overflow-hidden">
          <div 
            style={{
              transform: `translateX(-${currentIndex * 100}%)`,
              transition: 'transform 0.5s ease-in-out',
              display: 'flex'
            }}
          > 
            {testimonials.map((testimonial, index) => (
              <div 
                key={index}
                className="w-full flex-none min-h-[200px] md:min-h-[160px]"
              >
                <div className="h-full flex flex-col justify-between">
                  <div className="mb-4">
                    <p className="text-custom_neutral text-lg italic">
                      "{testimonial.testemonial}"
                    </p>
                  </div>
                  <div className="text-right">
                    <p className="text-custom_neutral">
                      - {testimonial.name}{testimonial.age ? `, ${testimonial.age}` : ''}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

            <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 flex space-x-2">
              {testimonials.map((_, index) => (
                <button
                  key={index}
                  className={`h-2 w-2 rounded-full ${
                    index === currentIndex ? 'bg-gray-800' : 'bg-gray-300'
                  }`}
                  onClick={() => handleSlide(index)}
                  aria-label={`Go to testimonial ${index + 1}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
  );
};

export default TestimonialCarousel;