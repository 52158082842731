import React from 'react';
import ArrowDown from '../icons/down_button.png';

const ScrollTopButton = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="w-full flex justify-center my-0">
      <button
        onClick={scrollToTop}
        className="p-0 rounded-full transition-colors duration-200 cursor-pointer"
        aria-label="Scroll to top of page"
      >
        <img 
          src={ArrowDown} 
          alt="Scroll to top" 
          className="w-16 h-16 rotate-180"
        />
      </button>
    </div>
  );
};

export default ScrollTopButton;