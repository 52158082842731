import React from 'react';
import { useContext } from 'react';
import mimData from "../data/MiM.json";
import { UserContext } from "../context/UserContext";

const GetDescriptionFromPoint = ({point}) => {
  const { isParent } = useContext(UserContext);
  
  if (isParent) {
    if (typeof point.description === 'object' && point.description.parts) {
      return (
        <p className="text-lg leading-relaxed text-gray-900">
          {point.description.parts.map((part, index) => (
            part.type === 'bold'
              ? <strong key={index} className="font-bold">{part.text}</strong>
              : <span key={index}>{part.text}</span>
          ))}
        </p>
      );
    }
    return <p className="text-lg leading-relaxed text-gray-900">{point.description}</p>;
  } else {
    return <p className="text-lg leading-relaxed text-gray-900">{point.description_kind}</p>;
  }
}

const GetTitleFromPoint = ({point}) => {
  const { isParent } = useContext(UserContext);
  
  if (isParent) {
    return <h1 className="text-2xl md:text-4xl font-bold text-center text-gray-900 mb-8">{point.title}</h1>;
  } else {
    return <h1 className="text-2xl md:text-4xl font-bold text-center text-gray-900 mb-8">{point.title_kind}</h1>;
  }
}

const MiMContent = ({ data }) => {
  const [imgSrc, setImgSrc] = React.useState(data.iconUrl);

  const handleImageError = () => {
    console.error(`Failed to load image: ${imgSrc}`);
    setImgSrc("./fallback-image.jpg");
  };

  return (
    <div 
      className="relative w-full max-w-4xl mx-auto overflow-hidden"
      style={{
        backgroundImage: `url(${imgSrc})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'local'
      }}
      onError={handleImageError}
    >
      {/* Semi-transparent white background for text readability */}
      <div className="absolute inset-0 bg-white/85"></div>
      
      {/* Content */}
      <div className="relative md:px-8">
        <GetTitleFromPoint point={data} />
        <GetDescriptionFromPoint point={data} />
      </div>
    </div>
  );
};

const MiM = () => (
    <div className="container text-justify mx-auto md:px-4 py-8 md:py-16">
      <MiMContent data={mimData} />
    </div>
);

export default MiM;