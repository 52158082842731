import React from 'react';
import { 
  MainContent, 
  HeaderWrapper, 
  ContentContainer, 
  HeaderContainer, 
  Title } 
  from "./StyledComponents";

function AGB() {
  return (
    <MainContent>
      <HeaderContainer> 
        <HeaderWrapper>
          <Title>AGB</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
        <h2>Allgemeine Geschäftsbedingungen der Codestart Academy</h2>
      </ContentContainer>
    </MainContent>
  );
}

export default AGB;
