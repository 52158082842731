import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  MainContent,
  HeaderWrapper,
  ContentContainer,
  HeaderContainer,
  Title,
  Text
} from "./StyledComponents";
import priceData from '../data/prices.json';

// Add CSS for the marquee animation
const bannerStyle = `
  @keyframes marquee {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-100%); }
  }
  
  .discount-banner {
    overflow: hidden;
    background-color: rgb(248, 107, 128);
    color: white;
    margin: 0rem -2rem 1rem -2rem;
    border-radius: 5px;
    position: relative;
    width: calc(100% + 4rem);
  }
  
  .discount-wrapper {
    display: flex;
    width: fit-content;
  }
  
  .discount-banner-text {
    white-space: nowrap;
    padding: 0.5rem 1rem;
    animation: marquee 15s linear infinite;
  }
`;

const PriceCard = ({ model }) => {
  return (
    <div className="flex flex-col p-6 pt-2 bg-custom_white rounded-xl border-4 border-custom_primary border-opacity-30 h-full">
      {/* Discount Banner */}
      <div className="discount-banner shadow-md">
        <div className="discount-wrapper">
          <div className="discount-banner-text">
            ** 5% Rabatt für Mädchen-in-MINT-Kurse! 1 kostenlose Unterrichtseinheit für jede geworbene Freundin und jeden geworbenen Freund! **
          </div>
          <div className="discount-banner-text">
            ** 5% Rabatt für Mädchen-in-MINT-Kurse! 1 kostenlose Unterrichtseinheit für jede geworbene Freundin und jeden geworbenen Freund! **
          </div>
        </div>
      </div>
      
      {/* Card Header - Shows the package title and minimum duration */}
      <div className="mb-4">
        <h3 className="text-xl font-bold bg-custom_primary shadow-md text-custom_white text-center rounded-3xl py-2 mb-2">{model.title}</h3>
      </div>
      
      {/* Price Information - Displays cost per unit and weekly schedule */}
      <div className="mb-6 text-center">
        <p className="text-3xl font-bold text-gray-800 mb-2">
          {model.price_per_UE === "kostenlos" ? "Kostenlos" : `${model.price_per_week}`}
        </p>
        <p className='text-custom_neutral font-light text-sm'>{model.freq}</p>
      </div>
      
      {/* Payment Details - Shows the payment schedule at the bottom of the card */}
      <div className="mt-auto">
        <ul className='list-disc'>
          <li className="text-custom_neutral font-medium my-2">{model.weekly}</li>
          <li className="text-custom_neutral font-medium my-2">{model.UEs} Unterrichtseinheiten</li>
          <li className="text-custom_neutral font-medium my-2">Mindestlaufzeit: {model.minimum_duration}</li>
          <li className="text-custom_neutral font-medium my-2">{model.payment}</li>
        </ul>
      </div>
    </div>
  );
};

// Main Preise component
const Preise = () => {
  // Add the CSS to the document when the component mounts
  useEffect(() => {
    // Create style element
    const styleElement = document.createElement('style');
    styleElement.innerHTML = bannerStyle;
    document.head.appendChild(styleElement);
    
    // Cleanup when component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);
  
  return (
    <MainContent>
      <HeaderContainer>
        <HeaderWrapper>
          <Title>Mitgliedschaften</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
        <Text className="mb-8">
          Die Preise für unsere Programmierkurse richten sich nach dem jeweiligen Mitgliedschaftspaket, dass du für dein Kind wählst.
        </Text>
        <div className="space-y-6">
          {/* Top row container: three cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {priceData.price_models.slice(0, 3).map((model) => (
              <div key={model.id}>
                <PriceCard model={model} />
              </div>
            ))}
          </div>
          {/* Bottom row container: two centered cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:w-2/3 lg:mx-auto">
            {priceData.price_models.slice(3).map((model) => (
              <div key={model.id}>
                <PriceCard model={model} />
              </div>
            ))}
          </div>
        </div>
        <p className='text-sm text-center font-custom_neutral mt-8'>*In Ferienzeiten und an gesetzlichen Feiertagen finden keine Programmierkurse statt.</p>
        <div className='w-fit text-xl font-bold bg-custom_tertiary shadow-md text-center rounded-3xl py-2 px-4 my-10 mx-auto'>
          <Link to="/courses" className='no-underline text-custom_white hover:text-custom_neutral'>Jetzt Kurs aussuchen und anfragen</Link>
        </div>
      </ContentContainer>
    </MainContent>
  );
};

export default Preise;