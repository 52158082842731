import React from 'react';
import { 
  MainContent, 
  HeaderWrapper, 
  ContentContainer, 
  HeaderContainer, 
  Title} 
  from "./StyledComponents";

function Impressum() {
  return (
    <MainContent>
      <HeaderContainer> 
        <HeaderWrapper>
          <Title>Impressum</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
        <h2>Impressum der Codestart Academy</h2>
          <p>Lucas Fortune<br />
            Kreuzstra&szlig;e 16<br />
            04103 Leipzig</p>
          <h3>Kontakt</h3>
          <p> {/*Telefon: &#91;Telefonnummer&#93;<br />*/}
            E-Mail: info@codestart.online</p>
          <h3>EU-Streitschlichtung</h3>
          <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>
          <h3>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h3> 
          <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
      </ContentContainer>
    </MainContent>
  );
}

export default Impressum;
