import React, { useState, useRef, useEffect} from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import styles from './Quiz.module.css';
import { 
  MainContent, 
  HeaderWrapper, 
  ContentContainer, 
  HeaderContainer, 
  Title } 
  from "./StyledComponents";
import questions from '../data/quiz.json';

function Quiz() {
  const [answers, setAnswers] = useState(questions.map(() => null));
  const [score, setScore] = useState(null);
  const setAnswerSummary = useState(null);
  const questionRefs = useRef(questions.map(() => React.createRef()));

  useEffect(() => {
    // Save answers to sessionStorage whenever they change
    sessionStorage.setItem('quizAnswers', JSON.stringify(answers));
  }, [answers]);

  const handleAnswerChange = (questionIndex, optionIndex) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = optionIndex;
    setAnswers(newAnswers);

    if (questionIndex < questions.length - 1) {
      const nextQuestionElement = questionRefs.current[questionIndex + 1].current;
      const yOffset = -95; // Adjust this value to fine-tune the scroll position
      const y = nextQuestionElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
  
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const calculateScore = () => {
    let newScore = 0;
    const summary = questions.map((question, index) => {
      const isCorrect = answers[index] === question.correctAnswer;
      if (isCorrect) newScore++;
      return {
        question: question.question,
        selectedAnswer: question.options[answers[index]],
        correctAnswer: question.options[question.correctAnswer],
        isCorrect
      };
    });
    setScore(newScore);
    const answerSummaryData ={ score: newScore, summary };
    setAnswerSummary(answerSummaryData);
    sessionStorage.setItem('answerSummary', JSON.stringify(answerSummaryData));
  };

  const resetQuiz = () => {
    setAnswers(questions.map(() => null));
    setScore(null);
    setAnswerSummary(null);
    scroll.scrollToTop();
  };

  const scrollToTop = () => {
    scroll.scrollToTop({
      duration: 30, // Duration in milliseconds
      smooth: true,
    });
  };

  return (
    <MainContent>
      <HeaderContainer>
        <HeaderWrapper>
          <Title>Quiz</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
          <p className={styles.description_Text}>Dieses Quiz ist dafür da, 
            um Ihr Kind bei uns richtig einzustufen. 
            Die Fragen sind deshalb an Ihr Kind gerichtet 
            und sollen so ehrlich wie möglich beantwortet werden!
          </p>
          <div className={styles.sidebar}>
            {questions.map((_, index) => (
              <ScrollLink 
                key={index}
                to={`question-${index}`} 
                smooth={true}
                duration={500} 
                className={styles.sidebarLink}
              >
              </ScrollLink>
            ))}
          </div>
          <form onSubmit={(e) => { e.preventDefault(); calculateScore(); }}>
            {questions.map((question, questionIndex) => (
              <div 
                key={questionIndex} 
                id={`question-${questionIndex}`} 
                ref={questionRefs.current[questionIndex]} 
                className={styles.questionContainer}
              >
                <h3 className={styles.questionText}>{question.question}</h3>
                <div className={styles.optionsContainer}>
                  {question.options.map((option, optionIndex) => {
                    const isSelected = answers[questionIndex] === optionIndex;
                    const isCorrect = optionIndex === question.correctAnswer;
                    const isIncorrect = isSelected && optionIndex !== question.correctAnswer;

                    return (
                      <label
                        key={optionIndex}
                        className={`${styles.optionLabel} ${
                          score !== null && (isCorrect ? styles.correctBackground : isIncorrect ? styles.incorrectBackground : '')
                        }`}
                      >
                        <input
                          type="radio"
                          name={`question-${questionIndex}`}
                          value={optionIndex}
                          checked={isSelected}
                          onChange={() => handleAnswerChange(questionIndex, optionIndex)}
                          disabled={score !== null}
                          className={styles.radioInput}
                        />
                        <span className={styles.optionText}>
                          {option}
                        </span>
                        {score !== null && (
                          <span className={`${styles.feedbackIcon} ${
                            isCorrect 
                              ? styles.correct 
                              : (isIncorrect ? styles.incorrect : '')
                          }`}>
                            {isCorrect && '✓'}
                            {isIncorrect && '✗'}
                          </span>
                        )}
                      </label>
                    );
                  })}
                </div>
              </div>
            ))}
            <div className={styles.submitContainer}>
              {score === null ? (
                <button type="submit" className={styles.submitButton}>Submit Answers</button>
              ) : (
                <div className={styles.scoreDisplay}>
                  <p>Your score: {score} out of {questions.length}</p>
                  <button type="button" className={styles.resetButton} onClick={resetQuiz}>Retake Quiz</button>
                  <a href="/contact" className={styles.submitButton}>Abgeben</a>
                </div>
              )}
            </div>
          </form>
          <button onClick={scrollToTop} className={styles.scrollToTopButton}>⬆</button>
      </ContentContainer>
    </MainContent>
  );
}

export default Quiz;
