import React, { useState, useEffect, useCallback } from 'react';

const FallingBackground = ({
  svgCount = 15,
  minSpeed = 20,
  maxSpeed = 40,
  svgImages = [
    '/backgroundSVGs/controller.svg',
    '/backgroundSVGs/CS.svg',
    '/backgroundSVGs/lightbulb.svg',
    '/backgroundSVGs/mim.svg',
    '/backgroundSVGs/react.svg',
    '/backgroundSVGs/robot.svg',
    '/backgroundSVGs/scratch.svg',
    '/backgroundSVGs/sword.svg'
  ],
  minSize = 20,
  maxSize = 60
}) => {
  const [fallingItems, setFallingItems] = useState([]);
  
  // Memoize createFallingItem function to fix dependency warning
  const createFallingItem = useCallback(() => {
    return {
      id: Math.random().toString(36).substr(2, 9),
      image: svgImages[Math.floor(Math.random() * svgImages.length)],
      position: -maxSize, // Start above the viewport
      left: Math.random() * 100, // Random horizontal position (%)
      size: Math.floor(Math.random() * (maxSize - minSize + 1)) + minSize,
      speed: (Math.floor(Math.random() * (maxSpeed - minSpeed + 1)) + minSpeed) / 10,
      rotation: Math.floor(Math.random() * 360),
      rotationSpeed: (Math.random() - 0.5) * 2 // Random rotation direction and speed
    };
  }, [svgImages, minSize, maxSize, minSpeed, maxSpeed]);
  
  useEffect(() => {
    // Generate initial falling items
    const initialItems = Array.from({ length: svgCount }, () => createFallingItem());
    setFallingItems(initialItems);
    
    // Set up animation loop
    const animationInterval = setInterval(() => {
      setFallingItems(prevItems => {
        return prevItems.map(item => {
          // Update position based on speed
          const newPosition = item.position + item.speed;
          
          // If item has moved beyond the screen, reset it at the top with new random properties
          if (newPosition > window.innerHeight) {
            return createFallingItem();
          }
          
          // Otherwise, update its position
          return {
            ...item,
            position: newPosition
          };
        });
      });
    }, 50); // Update every 50ms for smooth animation
    
    // Clean up interval on component unmount
    return () => clearInterval(animationInterval);
  }, [svgCount, createFallingItem]);
  
  return (
    <div className="fixed inset-0 w-full h-full -z-9 overflow-hidden pointer-events-none">
      {fallingItems.map(item => (
        <div
          key={item.id}
          className="absolute"
          style={{
            left: `${item.left}%`,
            top: `${item.position}px`,
            width: `${item.size}px`,
            height: `${item.size}px`,
            transform: `rotate(${item.rotation + item.rotationSpeed * item.position}deg)`,
            transition: 'transform 0.5s linear'
          }}
        >
          <img
            src={item.image}
            alt="Falling item"
            className="w-full h-full object-contain opacity-80"
          />
        </div>
      ))}
    </div>
  );
};

export default FallingBackground;