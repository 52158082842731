import React, { useState } from "react";
import {
  MainContent,
  HeaderWrapper,
  ContentContainer,
  HeaderContainer,
  Title,
  Text
} from "./StyledComponents";
import positionData from '../data/open_positions.json';

const JobPosition = ({ position }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="mb-4">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left p-4 bg-white rounded-lg shadow-md hover:shadow-lg transition-shadow duration-200 flex justify-between items-center"
      >
        <span className="text-lg font-semibold text-gray-800">{position.position_title}</span>
        <svg
          className={`w-6 h-6 transform transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="mt-2 p-4 bg-gray-50 rounded-lg">
          <div className="mb-4">
            <p className="text-gray-600 font-medium">{position.type}</p>
          </div>

          <div className="mb-4">
            <h5 className="font-semibold mb-2">Aufgaben:</h5>
            <ul className="list-disc pl-5 space-y-1">
              {position.tasks.map((task, index) => (
                <li key={index} className="text-gray-700">{task}</li>
              ))}
            </ul>
          </div>

          <div className="mb-4">
            <h5 className="font-semibold mb-2">Ihr Profil:</h5>
            <ul className="list-disc pl-5 space-y-1">
              {position.profile.map((item, index) => (
                <li key={index} className="text-gray-700">{item}</li>
              ))}
            </ul>
          </div>

          <div>
            <h5 className="font-semibold mb-2">Unser Angebot:</h5>
            <p className="text-gray-700">{position.offer}</p>
          </div>
        </div>
      )}
    </div>
  );
};

const Jobs = () => {
  const openPositions = positionData.open_positions.filter(
    position => position.open === "yes"
  );

  return (
    <MainContent>
      <HeaderContainer>
        <HeaderWrapper>
          <Title>Aktuelle Stellenangebote</Title>
        </HeaderWrapper>
      </HeaderContainer>
      <ContentContainer>
          {openPositions.length > 0 ? (
            <div className="mt-6">
              {openPositions.map((position, index) => (
                <JobPosition key={index} position={position} />
              ))}
            </div>
          ) : (
            <div className="mt-6 p-4 rounded-lg text-center">
              <Text>Momentan stehen leider keine offenen Stellen zur Verfügung.</Text>
            </div>
          )}
      </ContentContainer>
    </MainContent>
  );
};

export default Jobs;