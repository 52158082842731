import React from 'react';
import {
  MainContent,
  HeaderWrapper,
  ContentContainer,
  HeaderContainer,
  Subtitle,
  Text
} from "./StyledComponents";
import ScrollButton from './ScrollButton';
import FiveReasons from './FiveReasons';
import MiM from './MiM';
import TestimonialCarousel from './TestimonialCarousel';
import testimonials from '../data/testimonials.json';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';

const Home = () => {
  const { isParent } = useContext(UserContext);

  if (isParent === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <MainContent>
        <HeaderContainer>
          <HeaderWrapper>
          </HeaderWrapper>
        </HeaderContainer>
        {isParent ? (
        <>
          <ContentContainer>

                    <div className="relative mb-12">
                      <Subtitle className="mx-auto font-bold tracking-wider font-verdana">
                        Willkommen bei der
                      </Subtitle>
                      <img 
                        src="./logos/CS_logo_full.svg" 
                        alt="CS main logo" 
                        className="mx-auto w-full absolute animate-[pop_0.6s_cubic-bezier(0.34,1.56,0.64,1)]" 
                      />
                      <Subtitle className="mx-auto pt-[30%] font-bold tracking-wider font-verdana">
                        Academy!
                      </Subtitle>
                    </div>
                    <Text className="text-center mx-8">
                      Sieh dir jetzt unser <a href="/courses" className="no-underline text-[#008650] font-bold font-verdana hover:underline">Kursangebot</a> an,
                      finde den passenden Programmierkurs für dein Kind mit unserem <a href="/quizcarousel" className="no-underline text-[#008650] font-bold font-verdana hover:underline">Einstufungsquiz</a> oder
                      sende uns eine <a href="/contact" className="no-underline text-[#008650] font-bold font-verdana hover:underline">Anfrage</a> und starte das Codestart Abenteuer jetzt!
                    </Text>

            </ContentContainer>

            <ScrollButton />

              <ContentContainer>
                <FiveReasons />
              </ContentContainer>

            <ScrollButton />

            <ContentContainer>
                <MiM />
              </ContentContainer>

            <ScrollButton />

            <ContentContainer>
            <TestimonialCarousel testimonials={testimonials} />
              </ContentContainer>
        </>
        ) : (
        <>
          <ContentContainer>
                <div className="relative mb-12">
                  <Subtitle className="mx-auto font-bold tracking-wider font-verdana">
                    Hey Kinder! Willkommen bei der
                  </Subtitle>
                  <img 
                    src="./logos/CS_logo_full.svg" 
                    alt="CS main logo" 
                    className="mx-auto w-full absolute animate-[pop_0.6s_cubic-bezier(0.34,1.56,0.64,1)]" 
                  />
                  <Subtitle className="mx-auto pt-[30%] font-bold tracking-wider font-verdana">
                    Academy!
                  </Subtitle>
                </div>
                <Text className="text-center">
                  Schau dir unsere spannenden <a href="/courses" className="no-underline text-[#008650] font-bold font-verdana hover:underline">Programmierkurse</a> an,
                  teste dein Wissen im <a href="/quizcarousel" className="no-underline text-[#008650] font-bold font-verdana hover:underline">Quiz</a> oder
                  schreibe direkt eine <a href="/contact" className="no-underline text-[#008650] font-bold font-verdana hover:underline">Nachricht</a> an uns!
                  Hier findest du bestimmt etwas Tolles für dich!
                </Text>
                <Text className="text-center">
                  Dein CodeStart Abenteuer beginnt jetzt! Bist du bereit?
                </Text>
          </ContentContainer>

              <ContentContainer>
                <FiveReasons />
              </ContentContainer>

              <ContentContainer>
                <MiM />
              </ContentContainer>
          </>
        )}
      </MainContent>
    </>
  );
};

export default Home;